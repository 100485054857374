import React, { Component } from 'react'
import ChevronUp from '../../../images/chevron-up-solid.svg'
import styled from '@emotion/styled'
import classNames from 'classnames'

const FilterDropdown = styled('div')`
  display: none;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: block;
    font-size: 30px;
    line-height: calc(36px / 30px);
    padding: 10px 0;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    font-weight: 300;
    height: 58px;
    text-align: center;
    width: 296px;
    border-radius: 32.5px;
    font-family: ${props => props.theme.fonts.primaryRegular};
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
    transition: transform 0.6s cubic-bezier(0.6, 0.56, 0.6, 1.24);
  }

  &.open {
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      border-radius: 32.5px;
    }
  }

  &.whoop {
    transform: translateY(100px);
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
  }
`

const Chevron = styled('img')`
  width: 14px;
  height: auto;
  margin-left: 6px;
  position: relative;
  top: 5px;

  &.down {
    transform: rotate(180deg);
  }
`

export default class DropdownButton extends Component {
  render() {
    const { dropDownOpen, toggleDropdown, hideDropDown } = this.props
    const dropDownClass = classNames({
      open: dropDownOpen,
      whoop: hideDropDown,
    })
    return (
      <FilterDropdown className={dropDownClass} onClick={toggleDropdown}>
        Filter Projects{' '}
        <Chevron
          src={ChevronUp}
          className={dropDownOpen ? 'down' : ''}
          alt=""
        />
      </FilterDropdown>
    )
  }
}
