import React from 'react'
import styled from '@emotion/styled'

const ServicesTagWrapper = styled('div')`
  display: flex;
  justify-content: row;
  color: #fff;
  margin: 14px 0 61px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 14px 0 44px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 5px 0 40px;
  }
`

const ServicesTag = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
  margin: 0 30px 0 0;

  &:last-child {
    margin-right: 0;
  }

  &.strategy {
    color: #a5c93d;
  }

  &.design {
    color: #ff003b;
  }

  &.engineering {
    color: #009bec;
  }

  &.product-marketing {
    color: #deac54;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 10px;
    line-height: 13px;
  }
`

const stringToClassName = string =>
  string !== undefined ? string.replace(/ /g, '-').toLowerCase() : ''

const ServicesTags = ({ services }) => {
  return (
    <ServicesTagWrapper>
      {services.map(({ service }, i) => (
        <ServicesTag key={i} className={stringToClassName(service)}>
          {service}
        </ServicesTag>
      ))}
    </ServicesTagWrapper>
  )
}

export default ServicesTags
