import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import ServiceTags from './ServiceTags'

export const ShowcaseTile = styled('div')`
  flex-basis: 50%;
  width: 50%;

  &:nth-of-type(even) {
    padding-left: 22.5px;
  }

  &:nth-of-type(odd) {
    padding-right: 22.5px;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 50%;
    &:nth-of-type(even) {
      padding-left: 14px;
    }

    &:nth-of-type(odd) {
      padding-right: 14px;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-basis: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    &:nth-of-type(even),
    &:nth-of-type(odd) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const ShowcaseImage = styled('img')`
  height: auto;
  max-width: 100%;
`

const Clients = props => (
  <StaticQuery
    query={graphql`
      query ClientsQuery {
        allContentfulClient(limit: 6, sort: { fields: weight, order: ASC }) {
          edges {
            node {
              id
              name
              weight
              services {
                service
              }
              image {
                title
                description
                file {
                  url
                }
                resize {
                  src
                }
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.allContentfulClient
      return edges.map(({ node }, i) => (
        <ShowcaseTile key={i}>
          <ShowcaseImage
            src={node.image.file.url}
            srcSet={node.image.fluid.srcSet}
            alt={node.name}
          />
          <ServiceTags services={node.services} />
        </ShowcaseTile>
      ))
    }}
  />
)

export default Clients
