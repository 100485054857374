import React from 'react'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import Work from '../components/Work/index'
import { Helmet } from 'react-helmet'

const metaTitle = 'See Our Work - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'We partner with businesses from various industries to transform their ideas into digital experiences. See our work for Nestle, League, Freshbooks, and more.'

const OurWork = () => (
  <Layout footerCTA={true}>
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
    <StickyNav />
    <Work />
  </Layout>
)

export default OurWork
