import React from 'react'
import styled from '@emotion/styled'
import Categories from './Categories/index'
import Clients from './Clients/Clients'
import LoadMore from './Clients/LoadMore'
import cms from '../../util/cms'

export const Container = styled('div')`
  padding-left: 74px;
  padding-right: 74px;
  max-width: 1348px;
  margin: 0 auto;
  background: ${props => (props.bgcolor ? props.bgcolor : '#000')};
  position: relative;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const HeroContainer = styled('div')`
  padding-top: 289px;
  padding-bottom: 61px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 274px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 176px;
  }
`

const TitleWrapper = styled('div')`
  text-align: center;
  width: 100%;
`

const PageTitle = styled('h1')`
  color: #9b9b9b;
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 55px;
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  background-image: linear-gradient(
    78deg,
    #c772a3,
    #4c5465 28%,
    #3e5362 49%,
    #2092c4
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin: 0 auto 23px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 32px;
  }
`

const SubTitle = styled('h2')`
  color: #fff;
  margin: 0 auto;
  height: 72px;
  max-width: 577px;
  color: #ffffff;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 30px;
  font-weight: 200;
  line-height: 36px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 28px;
    line-height: 30px;
  }
`

const ClientShowcaseWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 0;
    margin-top: 115px;
  }
`

export default class Work extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clients: [],
      isLoading: false,
      allLoaded: false,
      limit: 6,
      loaded: 6,
      total: 0,
      activeCategory: '',
      showSSRClients: true,
      dropDownOpen: false,
      hideDropDown: false,
    }
    this.workWrapper = React.createRef()
  }

  /**
   * Hide the filter dropdown if user scrolls past
   * projects section on work pages
   *
   */

  hideDropdown = () => {
    const currentScrollPosition = window.pageYOffset
    const viewPortHeight =
      this.workWrapper.current.clientHeight - window.innerHeight
    if (currentScrollPosition > viewPortHeight) {
      this.setState({ hideDropDown: true, dropDownOpen: false })
    } else {
      this.setState({ hideDropDown: false })
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.hideDropdown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.hideDropdown, false)
  }

  toggleDropdown = bool =>
    this.setState({ dropDownOpen: !bool ? bool : !this.state.dropDownOpen })

  handleFilter = e => {
    e.preventDefault()
    const { limit } = this.state
    const currentCategory =
      e.target.textContent === 'All Projects' ? '' : e.target.textContent
    this.setState({
      isLoading: true,
      activeCategory: currentCategory,
      dropDownOpen: false,
    })
    cms
      .getEntries({
        content_type: 'client',
        order: 'fields.weight',
        'fields.category': currentCategory,
        limit,
        skip: 0,
      })
      .then(({ items, total }) => {
        const clients = items.map(({ fields }) => fields)
        this.setState({
          clients: clients,
          isLoading: false,
          loaded: limit,
          total,
          showSSRClients: false,
        })
      })
      .catch(err => console.log('Whoops:', err))
  }

  loadMore = () => {
    this.setState({ isLoading: true })
    const { limit, loaded, activeCategory } = this.state
    cms
      .getEntries({
        content_type: 'client',
        order: 'fields.weight',
        'fields.category': activeCategory,
        limit,
        skip: loaded,
      })
      .then(({ items, total }) => {
        const clients = items.map(({ fields }) => fields)
        this.setState({
          clients: [...this.state.clients, ...clients],
          isLoading: false,
          loaded: this.state.loaded + limit,
          total,
        })
      })
      .catch(err => console.log('Whoops:', err))
  }

  render() {
    const {
      total,
      loaded,
      isLoading,
      clients,
      showSSRClients,
      activeCategory,
      dropDownOpen,
      hideDropDown,
    } = this.state
    return (
      <HeroContainer ref={this.workWrapper}>
        <Container>
          <TitleWrapper>
            <PageTitle>Our Work</PageTitle>
            <SubTitle>
              We partner with businesses from various industries to transform
              their ideas into digital experiences.
            </SubTitle>
          </TitleWrapper>
          <Categories
            handleFilter={this.handleFilter}
            activeCategory={activeCategory}
            dropDownOpen={dropDownOpen}
            toggleDropdown={this.toggleDropdown}
            hideDropDown={hideDropDown}
          />
          <ClientShowcaseWrapper>
            {showSSRClients && <Clients />}
            <LoadMore
              loadMore={this.loadMore}
              clients={clients}
              total={total}
              loaded={loaded}
              isLoading={isLoading}
            />
          </ClientShowcaseWrapper>
        </Container>
      </HeroContainer>
    )
  }
}
