import React, { Component } from 'react'
import styled from '@emotion/styled'
import DropdownButton from './DropdownButton'
import classNames from 'classnames'

export const Container = styled('div')`
  padding-left: 74px;
  padding-right: 74px;
  max-width: 1348px;
  margin: 0 auto;
  background: ${props => (props.bgcolor ? props.bgcolor : '#000')};
  position: relative;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const LinksWrapper = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding-top: 62px;
  padding-bottom: 93px;
  max-width: 921px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: flex;
    padding: 0;
    position: fixed;
    bottom: 10px;
    width: 296px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    z-index: 99;
    transition: visibility 0.6s linear;

    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      bottom: max(10px, env(safe-area-inset-bottom) + 5px);
    }

    &.hidden {
      visibility: hidden;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0 12px;
    width: 100%;
  }
`

const Links = styled('div')`
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 47px 0 0;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: auto;
    width: 100%;

    &.close {
      display: none;
    }

    &.open {
      display: flex;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 296px;
  }
`

const Link = styled('a')`
  text-decoration: none;
  cursor: pointer;
  margin: 0 22px;
  color: #c7c7c7;
  position: relative;
  font-family: ${props => props.theme.fonts.sourceCode};
  text-transform: uppercase;
  font-size: 16px;

  &.active {
    text-decoration: underline;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      color: #fff;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 0 0 38px;
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;

    &:last-child {
      margin: 0 0 32px;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0 0 35px;
  }
`

export default class Categories extends Component {
  handleOutsideClick = e => {
    if (this.node.contains(e.target)) {
      return
    }
    this.props.toggleDropdown(false)
  }

  events = ['mousedown', 'touchmove']

  componentDidMount() {
    this.events.map(evt =>
      document.addEventListener(evt, this.handleOutsideClick, false)
    )
  }

  componentWillUnmount() {
    this.events.map(evt =>
      document.removeEventListener(evt, this.handleOutsideClick, false)
    )
  }

  render() {
    const {
      activeCategory,
      handleFilter,
      dropDownOpen,
      toggleDropdown,
      hideDropDown,
    } = this.props
    const categories = [
      'All Projects',
      'Web Applications',
      'Mobile Applications',
      'Marketing Websites',
    ]

    const linkWrapperClass = classNames({
      hidden: hideDropDown,
    })

    return (
      <Container>
        <LinksWrapper
          ref={node => (this.node = node)}
          className={linkWrapperClass}
        >
          <Links className={dropDownOpen ? 'open' : 'close'}>
            {categories.map((item, i) => (
              <Link
                className={
                  item === activeCategory ||
                  (activeCategory === '' && item === 'All Projects')
                    ? 'active'
                    : ''
                }
                key={i}
                onClick={handleFilter}
              >
                {item}
              </Link>
            ))}
          </Links>
          <DropdownButton
            dropDownOpen={dropDownOpen}
            toggleDropdown={toggleDropdown}
            hideDropDown={hideDropDown}
          />
        </LinksWrapper>
      </Container>
    )
  }
}
