import React, { Component } from 'react'
import styled from '@emotion/styled'
import { ShowcaseTile, ShowcaseImage } from './Clients'
import ServiceTags from './ServiceTags'
import { CTA, CTAText } from '../../Buttons/index'

const LoadMoreButton = CTA.withComponent('a')

const BtnWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0 30px;
`

export default class LoadMore extends Component {
  static defaultProps = {
    clients: [],
  }

  servicesReducer = services =>
    services.map(item => {
      return { service: item.fields.service }
    })

  dynamicClients = () =>
    this.props.clients.map((client, i) => {
      const { url } = client.image.fields.file
      const { name, services } = client
      const dynamicServices = this.servicesReducer(services)
      return (
        <ShowcaseTile key={i}>
          <ShowcaseImage src={url} srcSet="" alt={name} />
          <ServiceTags services={dynamicServices} />
        </ShowcaseTile>
      )
    })

  render() {
    const { total, loaded, isLoading, loadMore } = this.props
    const allLoaded =
      total === 0 || (loaded <= total && total !== 6) ? false : true

    return (
      <React.Fragment>
        {this.dynamicClients()}
        {!allLoaded && (
          <BtnWrapper>
            <LoadMoreButton disabled={isLoading} onClick={loadMore}>
              <CTAText>{isLoading ? 'Loading...' : 'Load More'}</CTAText>
            </LoadMoreButton>
          </BtnWrapper>
        )}
      </React.Fragment>
    )
  }
}
